import request from "@/utils/request";

/**
 * 网格管理
 * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7184} data
 * @returns
 */
// 获取网格树
export function getTree(data) {
  return request({
    url: "/api/area/getTreeByCode.json",
    method: "post",
    params: data,
  });
}
// 新增/修改
export function saveTree(data) {
  return request({
    url: "/api/area/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delTree(data) {
  return request({
    url: "/api/area/deleteData.json",
    method: "post",
    data,
  });
}

/**
 * 老人管理
 * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7205} data
 * @returns
 */
// 分页列表
export function getElderlyInfoList(data) {
  return request({
    url: "/api/elderlyInfo/getDataList.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveElderlyInfo(data) {
  return request({
    url: "/api/elderlyInfo/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delElderlyInfo(data) {
  return request({
    url: "/api/elderlyInfo/deleteData.json",
    method: "post",
    data,
  });
}

/**
 * 助残站点
 * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7212} data
 * @returns
 */
// 分页列表
export function getMealSiteList(data) {
  return request({
    url: "/api/mealSite/getDataList.json",
    method: "post",
    data,
  });
}
// 不分页列表
export function getMealSiteAllList(data) {
  return request({
    url: "/api/mealSite/getAllData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveMealSite(data) {
  return request({
    url: "/api/mealSite/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delMealSite(data) {
  return request({
    url: "/api/mealSite/deleteData.json",
    method: "post",
    data,
  });
}

/**
 * 站点菜单
 * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7219} data
 * @returns
 */
// 分页列表
export function getMealMenuList(data) {
  return request({
    url: "/api/mealMenu/getDataList.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveMealMenu(data) {
  return request({
    url: "/api/mealMenu/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delMealMenu(data) {
  return request({
    url: "/api/mealMenu/deleteData.json",
    method: "post",
    data,
  });
}

/**
 * 助餐记录
 * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7226} data
 * @returns
 */
// 分页列表
export function getMealRecordList(data) {
  return request({
    url: "/api/mealRecord/getDataList.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveMealRecord(data) {
  return request({
    url: "/api/mealRecord/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delMealRecord(data) {
  return request({
    url: "/api/mealRecord/deleteData.json",
    method: "post",
    data,
  });
}
// 流水
export function getMoney(data) {
  return request({
    url: "/api/mealRecord/getMoney.json",
    method: "post",
    data,
  });
}

/**
 * 高龄补贴
 * @param {https://console-docs.apipost.cn/preview/49c3e31c8121b05d/14bd6fec5d565f15?target_id=c019b052-0b2d-415e-b421-7cbe232f76bb} data
 * @returns
 */
// 分页列表
export function getOldAgeAllowanceList(data) {
  return request({
    url: "/api/elderlyInfo/getOldAgeAllowanceList.json",
    method: "post",
    data,
  });
}
// 发放补贴操作
export function saveOldAgeAllowance(data) {
  return request({
    url: "/api/oldAgeAllowance/saveData.json",
    method: "post",
    data,
  });
}
// 补贴历史记录
export function getOldDataList(data) {
  return request({
    url: "/api/oldAgeAllowance/getDataList.json",
    method: "post",
    data,
  });
}
// 充值记录
export function getRechargeList(data) {
  return request({
    url: "/api/rechargeRecord/getDataList.json",
    method: "post",
    data,
  });
}
// 充值历史记录
export function getDataListAll(data) {
  return request({
    url: "/api/rechargeRecord/getDataListAll.json",
    method: "post",
    data,
  });
}
// 充值记录查询总金额
export function getRechMoney(data) {
  return request({
    url: "/api/rechargeRecord/getMoney.json",
    method: "post",
    data,
  });
}

/**
 * 临淄慈善
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7415} data
 * @returns
 */
// 分页列表
export function getCharitableList(data) {
  return request({
    url: "/api/charitable/getDataList.json",
    method: "post",
    data,
  });
}
// 删除
export function delCharitable(data) {
  return request({
    url: "/api/charitable/deleteData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveCharitable(data) {
  return request({
    url: "/api/charitable/saveData.json",
    method: "post",
    data,
  });
}

/**
 * 辅具租赁
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7436} data
 * @returns
 */
// 分页列表
export function getRentalAuxiliaryList(data) {
  return request({
    url: "/api/rentalAuxiliary/getDataList.json",
    method: "post",
    data,
  });
}
// 删除
export function delRentalAuxiliary(data) {
  return request({
    url: "/api/rentalAuxiliary/deleteData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveRentalAuxiliary(data) {
  return request({
    url: "/api/rentalAuxiliary/saveData.json",
    method: "post",
    data,
  });
}

/**
 * 养老机构
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7548} data
 * @returns
 */
// 分页列表
export function getelderlyCareInstitutionList(data) {
  return request({
    url: "/api/elderlyCareInstitution/getDataList.json",
    method: "post",
    data,
  });
}
// 导出
export function getelderlyCareInstitutionExportData(data) {
  return request({
    url: "/api/elderlyCareInstitution/exportData",
    responseType: "blob",
    method: "post",
    data,
  });
}
// 删除
export function delelderlyCareInstitution(data) {
  return request({
    url: "/api/elderlyCareInstitution/deleteData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveelderlyCareInstitution(data) {
  return request({
    url: "/api/elderlyCareInstitution/saveData.json",
    method: "post",
    data,
  });
}

/**
 * 设施管理
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7562} data
 * @returns
 */
// 分页列表
export function getliveFacilityManagementList(data) {
  return request({
    url: "/api/liveFacilityManagement/getDataList.json",
    method: "post",
    data,
  });
}
// 删除
export function delliveFacilityManagement(data) {
  return request({
    url: "/api/liveFacilityManagement/deleteData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveliveFacilityManagement(data) {
  return request({
    url: "/api/liveFacilityManagement/saveData.json",
    method: "post",
    data,
  });
}

/**
 * 关爱探访
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7555} data
 * @returns
 */
// 分页列表
export function getcaringVisitsList(data) {
  return request({
    url: "/api/caringVisits/getDataList.json",
    method: "post",
    data,
  });
}
// 删除
export function delcaringVisits(data) {
  return request({
    url: "/api/caringVisits/deleteData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function savecaringVisits(data) {
  return request({
    url: "/api/caringVisits/saveData.json",
    method: "post",
    data,
  });
}
// 导出
export function downloadcaringVisits(data) {
  return request({
    url: "/api/caringVisits/exportData",
    responseType: "blob",
    method: "post",
    data,
  });
}

/**
 * 助餐管理-补贴标准管理
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7380} data
 * @returns
 */
// 分页列表
export function getmealSubsidyList(data) {
  return request({
    url: "/api/mealSubsidy/getDataList.json",
    method: "post",
    data,
  });
}
// 删除
export function delmealSubsidy(data) {
  return request({
    url: "/api/mealSubsidy/deleteData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function savemealSubsidy(data) {
  return request({
    url: "/api/mealSubsidy/saveData.json",
    method: "post",
    data,
  });
}

/**
 * 农村幸福院
 * @param {http://200.200.200.3:3000/project/2623/interface/api/58419} data
 * @returns
 */
// 列表
export function getHappinessInstituteDataList(data) {
  return request({
    url: "/api/ruralHappinessInstitute/getDataList",
    method: "post",
    data,
  });
}
// 新增
export function getHappinessInstituteSaveData(data) {
  return request({
    url: "/api/ruralHappinessInstitute/saveData",
    method: "post",
    data,
  });
}
// 导出
export function getHappinessInstituteExportData(data) {
  return request({
    url: "/api/ruralHappinessInstitute/exportData",
    method: "post",
    data,
  });
}
// 删除
export function getHappinessInstituteDeleteData(data) {
  return request({
    url: "/api/ruralHappinessInstitute/deleteData",
    method: "post",
    data,
  });
}
/**
 * 日间照料
 * @param {http://200.200.200.3:3000/project/2623/interface/api/58419} data
 * @returns
 */
export function getElderDataList(data) {
  return request({
    url: "/api/elderlyCareCenter/getDataList",
    method: "post",
    data,
  });
}
// 导出
export function getElderExportData(data) {
  return request({
    url: "/api/elderlyCareCenter/exportData",
    method: "post",
    data,
  });
}
// 删除
export function getElderExportDeleteData(data) {
  return request({
    url: "/api/elderlyCareCenter/deleteData",
    method: "post",
    data,
  });
}
/**
 * 适老化改造
 * @param {http://200.200.200.3:3000/project/2623/interface/api/58419} data
 * @returns
 */
// 列表
export function getElderOrientedDataList(data) {
  return request({
    url: "/api/elderlyOrientedReform/getDataList",
    method: "post",
    data,
  });
}
// 删除
export function getElderOrientedDleteData(data) {
  return request({
    url: "/api/elderlyOrientedReform/deleteData",
    method: "post",
    data,
  });
}
// 导出
export function getElderOrientedExportData(data) {
  return request({
    url: "/api/elderlyOrientedReform/exportData",
    method: "post",
    data,
  });
}
// 新增修改
export function getElderOrientedSaveData(data) {
  return request({
    url: "/api/elderlyOrientedReform/saveData",
    method: "post",
    data,
  });
}
// 大屏
export function getElderlyData(data) {
  return request({
    url: "/api/elderlyInfo/getElderlyData",
    method: "get",
    data,
  });
}
/**
 * 长者食堂
 * @param {https://doc.apipost.net/docs/detail/41517fa104e0000?target_id=152a46ceb12055} data
 * @returns
 */
// 列表
export function zzstDataList(data) {
  return request({
    url: "/api/elderlyCanteen/getDataList",
    method: "post",
    data,
  });
}
// 删除
export function zzstDeleteData(data) {
  return request({
    url: "/api/elderlyCanteen/deleteData",
    method: "post",
    data,
  });
}
// 导出
export function zzstExportData(data) {
  return request({
    url: "/api/elderlyCanteen/exportData",
    method: "post",
    data,
  });
}
// 新增修改
export function zzstSaveData(data) {
  return request({
    url: "/api/elderlyCanteen/saveData",
    method: "post",
    data,
  });
}
// 导入
export function zzstImportData(data) {
  return request({
    url: "/api/elderlyCanteen/importData.json",
    method: "post",
    data,
  });
}