<template>
  <div class="allNum">
    <div class="title">老年总体数据</div>
    <div class="content">
      <div class="old">
        <div class="box_old" v-for="(item, index) in old" :key="index">
          <div class="sui">{{ item.sui }}岁</div>
          <div class="bf">
            {{ item.bf }}
          </div>
          <div class="sum">{{ item.sum }}人</div>
        </div>
      </div>
      <div style="height: 5.63rem; border-left: 0.06rem solid #00ffff"></div>
      <div class="gender">
        <div class="box_old" v-for="(item, index) in gender" :key="index">
          <div class="sui">
            {{ item.sui }}
          </div>
          <div class="bf">
            {{ item.bf }}
          </div>
          <div class="sum">{{ item.sum }}人</div>
        </div>
      </div>
      <!-- <div style="height: 5.63rem; border-left: 0.06rem solid #00ffff"></div> -->
      <!-- <div class="aging">
        <div class="box_old" v-for="(item, index) in aging" :key="index">
          <div class="sui">
            {{ item.sui }}
          </div>
          <div class="bf">{{ item.bf }}(严重)</div>
          <div class="sum">{{ item.sum }}人</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { get } from "ol/proj";
export default {
  name: "allNum",

  data() {
    return {
      old: [],
      gender: [],
      aging: [
        {
          sui: "老龄化程度",
          bf: "26.48%",
          sum: "62239",
        },
      ],
    };
  },

  mounted() {},
  created() {
    this.getElderlyData();
  },
  methods: {
    // 获取老年人数据
    getElderlyData() {
      this.$store
        .dispatch("api/getElderlyData", {})
        .then((response) => {
          console.log(response, "response");
          if (response.success) {
            const data = response;
            this.old = [
              {
                sui: "60-80",
                bf: `${data.result.seventyPercent.toFixed(2)}%`,
                sum: `${data.result.seventy}`,
              },
              {
                sui: "80-89",
                bf: `${data.result.eightyPercent.toFixed(2)}%`,
                sum: `${data.result.eighty}`,
              },
              {
                sui: "90-99",
                bf: `${data.result.ninetyPercent.toFixed(2)}%`,
                sum: `${data.result.ninety}`,
              },
              {
                sui: "≥100",
                bf: `${data.result.hundredPercent.toFixed(2)}%`,
                sum: `${data.result.hundred}`,
              },
            ];
            this.gender = [
              {
                sui: "男性",
                bf: `${data.result.malePercent.toFixed(2)}%`,
                sum: `${data.result.male}`,
              },
              {
                sui: "女性",
                bf: `${data.result.femalePercent.toFixed(2)}%`,
                sum: `${data.result.femalePercent}`,
              },
            ];
            console.log(this.old, "this.old");
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.allNum {
  height: 100%;
  width: calc(100% - 26px);
  background: url("~@/assets/content/box_bg2.png");
  background-size: 100% 100%;
  padding: 13px;
  z-index: 99;

  .title {
    height: 1.74rem;
    width: 20.43rem;
    background: url("~@/assets/content/titleB.png");
    background-size: 100% 100%;
    font-size: 1rem;
    line-height: 1.74rem;
    font-family: DOUYU, DOUYU-常规体;
    font-weight: normal;
    text-align: left;
    color: #ffffff;
    padding-left: 13px;
  }

  .content {
    height: calc(100% - 1.74rem);
    width: 100%;
    display: flex;
    align-items: center;

    .old {
      flex: 4;
      display: flex;
      justify-content: space-around;
    }

    .gender {
      flex: 2;
      display: flex;
      justify-content: space-around;
    }

    .aging {
      flex: 1;
      display: flex;
      justify-content: space-around;
    }

    .box_old {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .sui {
        font-size: 1rem;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: center;
        color: #fff;
      }

      .bf {
        font-size: 1.13rem;
        font-family: DOUYU, DOUYU-常规体;
        font-weight: normal;
        text-align: center;
        color: #fff;
      }

      .sum {
        width: 7.25rem;
        height: 2.75rem;
        background-image: url("~@/assets/content/xz.png");
        background-size: 100% 100%;
        font-size: 0.88rem;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        text-align: center;
        color: #fff;
        line-height: 1.56rem;
      }
    }
  }
}
</style>