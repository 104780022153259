import * as api from "@/api/api";
import { zzstDataList, zzstDeleteData, zzstExportData, zzstImportData, zzstSaveData } from "../../api/api";
const actions = {
  getTree({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getTree(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改

  saveTree({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveTree(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除

  delTree({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delTree(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 分页列表
  getElderlyInfoList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getElderlyInfoList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveElderlyInfo({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveElderlyInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delElderlyInfo({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delElderlyInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMealSiteList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getMealSiteList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMealSiteAllList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getMealSiteAllList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveMealSite({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveMealSite(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delMealSite({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delMealSite(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 站点菜单
   * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7219} data
   * @returns
   */
  // 分页列表
  getMealMenuList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getMealMenuList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveMealMenu({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveMealMenu(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delMealMenu({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delMealMenu(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 助餐记录
   * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7226} data
   * @returns
   */
  // 分页列表
  getMealRecordList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getMealRecordList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveMealRecord({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveMealRecord(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delMealRecord({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delMealRecord(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 流水
  getMoney({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getMoney(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 高龄补贴
   * @param {https://console-docs.apipost.cn/preview/49c3e31c8121b05d/14bd6fec5d565f15?target_id=c019b052-0b2d-415e-b421-7cbe232f76bb} data
   * @returns
   */
  // 分页列表
  getOldAgeAllowanceList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getOldAgeAllowanceList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 发放补贴操作
  saveOldAgeAllowance({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveOldAgeAllowance(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 历史发放记录
  getOldDataList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getOldDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 充值记录
  getRechargeList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getRechargeList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 充值历史记录
  getDataListAll({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getDataListAll(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 充值总金额
  getRechMoney({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getRechMoney(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * 临淄慈善
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7415} data
   * @returns
   */
  // 分页列表
  getCharitableList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getCharitableList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delCharitable({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delCharitable(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveCharitable({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveCharitable(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * 辅具租赁
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7436} data
   * @returns
   */
  // 分页列表
  getRentalAuxiliaryList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getRentalAuxiliaryList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delRentalAuxiliary({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delRentalAuxiliary(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveRentalAuxiliary({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveRentalAuxiliary(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 养老机构
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7548} data
   * @returns
   */
  // 分页列表
  getelderlyCareInstitutionList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getelderlyCareInstitutionList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delelderlyCareInstitution({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delelderlyCareInstitution(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveelderlyCareInstitution({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveelderlyCareInstitution(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 设施管理
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7562} data
   * @returns
   */
  // 分页列表
  getliveFacilityManagementList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getliveFacilityManagementList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delliveFacilityManagement({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delliveFacilityManagement(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveliveFacilityManagement({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveliveFacilityManagement(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 关爱探访
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7555} data
   * @returns
   */
  // 分页列表
  getcaringVisitsList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getcaringVisitsList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delcaringVisits({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delcaringVisits(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  savecaringVisits({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .savecaringVisits(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 导出
  downloadcaringVisits({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .downloadcaringVisits(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * 助餐管理-补贴标准管理
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7380} data
   * @returns
   */
  // 分页列表
  getmealSubsidyList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getmealSubsidyList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delmealSubsidy({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delmealSubsidy(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  savemealSubsidy({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .savemealSubsidy(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 幸福农村
  getHappinessInstituteDataList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getHappinessInstituteDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增
  getHappinessInstituteSaveData({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getHappinessInstituteSaveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 导出
  getHappinessInstituteExportData({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getHappinessInstituteExportData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  getHappinessInstituteDeleteData({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getHappinessInstituteDeleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 日间照料
  getElderDataList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getElderDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 导出
  getElderExportData({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getElderExportData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  getElderExportDeleteData({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getElderExportDeleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 适老化改造
  getElderOrientedDataList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getElderOrientedDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getElderOrientedDleteData({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getElderOrientedDleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getElderOrientedExportData({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getElderOrientedExportData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getElderOrientedSaveData({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getElderOrientedSaveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getElderlyData({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getElderlyData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  zzstDataList({}, parm) {
    return new Promise((resolve, reject) => {
      zzstDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  zzstDeleteData({}, parm) {    
    return new Promise((resolve, reject) => {
      zzstDeleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  zzstExportData({}, parm) {
    return new Promise((resolve, reject) => {
      zzstExportData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  zzstImportData({}, parm) {
    return new Promise((resolve, reject) => {
      zzstImportData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  zzstSaveData({}, parm) {
    return new Promise((resolve, reject) => {
      zzstSaveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getelderlyCareInstitutionExportData({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getelderlyCareInstitutionExportData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
